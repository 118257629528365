$primary-white: whitesmoke;
$primary-blue: #0070c0;

$title-font: 35px;
$paragraph-font: 14px;

html {
  color: $primary-blue;
}

hr {
  margin-top: 15px;
  margin-bottom: 0px;
  border-color: $primary-blue;
}

h1,
h2,
h3 {
  color: $primary-blue;
}

p {
  color: black;
}

.App-link {
  color: $primary-blue;
}

a,
a:hover,
a:any-link {
  color: $primary-blue;
}

#app-container {
  overflow: hidden;
}

@media only screen and (max-width: 950px) {
  hr {
    display: none;
  }
}
