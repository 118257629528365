@use "../../App.scss" as app;

#blog-container {
  display: block;
  text-align: center;
  margin: auto 30px 50px 30px;
}

#blog-list {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.blog-list-item {
  text-align: center;
  margin: auto;
  font-size: larger;
  cursor: pointer;
}

.blog-list-item:hover {
  text-decoration: underline;
}

#blog-content-holder {
  display: block;
  text-align: left;
  margin: 25px;
}

#blog-content-holder ul li {
  color: black;
}