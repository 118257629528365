@use "../../App.scss";

#contact-container {
  display: block;
  margin: 50px 60px;
}

#contact-container h1 {
  text-align: center;
}

#contact-container p {
  margin: 20px 20px;
}

#contact-information {
  margin: 20px 20px;
}
