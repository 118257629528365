@use "../../App.scss" as app;

#home-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 30px;
}

#home-welcome-message {
  min-width: 100%;
  text-align: center;
}

h1 {
  font-size: app.$title-font;
}

#home-image-container {
  margin-top: 10px;
  min-width: 100%;
}

.carousel-root {
  max-width: 600px;
  height: auto;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.home-carousel-image {
  width: 100%;
  height: auto;
}

.home-content-block {
  margin: 0 40px;
}

@media only screen and (max-width: 950px) {
  #home-logo-img {
    width: 100%;
    height: auto;
  }

  .carousel-root {
    max-width: 80vw;
  }
}
