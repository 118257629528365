@use "../../App.scss" as app;

#header-container {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#header-container-mobile {
  display: none;
}

#header-container h1 {
  margin: 0 20px;
  font-style: italic;
}

#header-socials-container {
  margin: 0 15px;
}

.header-icon {
  margin: 0 5px;
  cursor: pointer;
}

#header-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.header-menu-item {
  margin: 0 30px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.header-menu-item-selected {
  margin: 0 30px;
  color: app.$primary-blue;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}

.header-menu-item:any-link {
  color: app.$primary-blue;
}

.header-menu-item:hover {
  text-decoration: underline;
}

.header-menu-item:visited {
  color: app.$primary-blue;
  text-decoration: none;
}

@media only screen and (max-width: 950px) {
  #header-container {
    display: none;
  }

  #header-container-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 20px;
  }

  #header-container-mobile h1 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: italic;
  }

  #header-mobile-tray {
    z-index: 5;
    width: 70vw;
    // height: 110vh;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    background-color: app.$primary-white;
    border-right: 2px solid app.$primary-blue;
    transition: visible 1px;
  }

  #header-menu-mobile {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-menu-item {
    line-height: 50px;
  }

  .header-menu-item-selected {
    line-height: 50px;
  }
}
