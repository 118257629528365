@use "../../App.scss" as app;

#services-container {
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 50px 30px;
}

#services-container h1 {
  text-align: center;
}

.services-content-block {
  display: block;
  margin: 0 30px;
}
