@use "../../App.scss" as app;

#competencies-container {
  display: block;
  margin: 0 30px;
}

#competencies-container h1 {
  text-align: center;
}

#competencies-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.competency-card {
  cursor: pointer;
  margin: 10px 50px;
  max-width: 100px;
  border: 2px solid app.$primary-blue;
  border-radius: 5px;
  padding: 30px;
}

.competency-card-selected {
  cursor: pointer;
  margin: 10px 50px;
  max-width: 100px;
  border: 2px solid app.$primary-blue;
  background-color: app.$primary-blue;
  color: app.$primary-white;
  border-radius: 5px;
  padding: 30px;
}

.competencies-content-block {
  margin: 0 30px;
}

.competencies-content-block h3 {
  margin: 30px 30px;
}

.competencies-content-block p {
  margin: 20px 40px;
}

@media only screen and (max-width: 950px) {
  #competencies-container {
    margin: 0 10px;
  }

  .competency-card {
    cursor: pointer;
    margin: 10px 20px;
    max-width: 100px;
    border: 2px solid app.$primary-blue;
    border-radius: 5px;
    padding: 30px;
  }
  
  .competency-card-selected {
    cursor: pointer;
    margin: 10px 20px;
    max-width: 100px;
    border: 2px solid app.$primary-blue;
    background-color: app.$primary-blue;
    color: app.$primary-white;
    border-radius: 5px;
    padding: 30px;
  }
}
