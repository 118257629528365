@use "../../App.scss" as app;

#ethos-container {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
}

#ethos-container h1 {
  text-align: center;
  font-size: app.$title-font;
}

.ethos-content-block {
  margin: 0 30px;
}

.ethos-content-subsection {
  margin-left: 20px;
}

.ethos-content-subsection p {
  margin-left: 10px;
}